import { Injectable } from "@angular/core" ;

import { AuthorizationFrontendService, ConfigurationFrontendService, UserFrontendService } from "@numen/smex-lib-common-frontend" ;
import { FooterConfig } from "../models";

@Injectable({
  providedIn: 'root'
})
export class CfnEcmFrontConfigurationService
{
    private footerConfig: FooterConfig = { columns: [] };

    constructor (
      private m_ConfigurationService : ConfigurationFrontendService,
      private m_AuthorizationService : AuthorizationFrontendService,
      private userService: UserFrontendService
    ) {}

    /** Appelé pour initialiser les données.
     */
    public async initialise () : Promise<void>
    {
      try {
        this.footerConfig = await this.m_AuthorizationService.get(
          this.m_ConfigurationService.getBackendApiUri("config") + '/footer'
        );
      } catch (e: unknown) {
        console.warn('Unable to load the footer config');
      }
    }

    /**
     * Obtenir un chemin complet pour un appel à l’API de la module de recherche production digitale.
     * @param Path    Le chemin à ajouter à l’adresse de base.
     */
    public getCfnEcmApiUri ( Path : string )
    {
        return ( this.m_ConfigurationService.getBackendApiUri ( "cfnecm" ) + Path ) ;
    }

    /**
     * Obtenir l'objet de configuration du footer
     */
    public getComputedFooterConfig() {
      const computedConfig: Record<string, any> = { columns: [] };
      const {locale} = this.userService;
      
      for(const column of this.footerConfig.columns) {
        computedConfig['columns'].push({
          title: {
            ...column.title.default,
            ...column.title[locale],
          },
          rows: column.rows.map(r => ({
            ...r.default,
            ...r[locale],
          }))
        });
      }

      return computedConfig;
    }

}
